<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="8" md="8" lg="6" xl="4">
        <v-card tile class="ma-0">
          <v-img :src="require('../assets/Images/Ristorante.avif')" width="100%" max-height="15vh" cover class="ma-0 pa-0">
          <v-card class="ma-0 pa-0" tile height="101%" outlined color="transparent" style="background: linear-gradient(to bottom, transparent 55%, black 100%);">
            <v-card height="60%" outlined tile color="transparent"></v-card>
            <v-card height="20%" outlined tile color="transparent"><h1 style="width: fit-content; margin: auto; color: white;">Menù Ristorante</h1></v-card>
          </v-card>
          </v-img>
        </v-card>
        <RestCard v-for="pizza, index in pizzaList" :key="index" :pizza="pizza" class="mb-1" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
  import RestCard from '../components/RestCard'

  export default {
    name: 'PizzeClassicheView',

    components: {
      RestCard,
    },
    data: () => ({
      pizzaList: [
        {
          Nome: "Antipasti",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Formaggi Misti con le nostre giardiniere",
          Ingredienti: "",
          Prezzo: "12,00",
          Icons: []
        },
        {
          Nome: "Salumi Misti con le nostre giardiniere",
          Ingredienti: "",
          Prezzo: "12,00",
          Icons: []
        },
        {
          Nome: "Formaggi servizio carrello",
          Ingredienti: "",
          Prezzo: "30,00",
          Icons: []
        },
        {
          Nome: "Primi di terra",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Chicche Verdi al Pomodoro",
          Ingredienti: " ",
          Prezzo: "9,00",
          Icons: []
        },
        {
          Nome: "Tortelli Piacentini Burro e Salvia",
          Ingredienti: " ",
          Prezzo: "11,00",
          Icons: []
        },
        {
          Nome: "Gnocchi allo Zola",
          Ingredienti: "",
          Prezzo: "11,00",
          Icons: []
        },
        {
          Nome: "Tagliatelle ai Funghi Porcini ",
          Ingredienti: "",
          Prezzo: "12,00",
          Icons: []
        },
        {
          Nome: "Secondi di terra",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Salsiccia ai ferri ",
          Ingredienti: "",
          Prezzo: "10,00",
          Icons: []
        },
        {
          Nome: "Cotoletta",
          Ingredienti: "",
          Prezzo: "10,00",
          Icons: []
        },
        {
          Nome: "Scaloppine al Limone/Vinobianco",
          Ingredienti: "",
          Prezzo: "11,00",
          Icons: []
        },
        {
          Nome: "Tagliata di Manzo al Rosmarino ",
          Ingredienti: "",
          Prezzo: "18,00",
          Icons: []
        },
        {
          Nome: "Grigliata Mista 1,6 Kg ",
          Ingredienti: "",
          Prezzo: "30,00",
          Icons: []
        },
        {
          Nome: "Contorni",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Patate Fritte",
          Ingredienti: "",
          Prezzo: "3,50",
          Icons: []
        },
        {
          Nome: "Insalata Mista",
          Ingredienti: "Foglia, Pomodoro, Cipolla",
          Prezzo: "4,50",
          Icons: []
        },
        {
          Nome: "Verdure Miste della casa",
          Ingredienti: "",
          Prezzo: "6,50",
          Icons: []
        },
        {
          Nome: "Oltre Menù",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Panini con Salume o Formaggi",
          Ingredienti: "",
          Prezzo: "4,50",
          Icons: []
        },
        {
          Nome: "Insalatona Mista",
          Ingredienti: "Foglia, Pomodoro, Carote, Olive, Tonno, Bufala",
          Prezzo: "8,00",
          Icons: []
        },
        {
          Nome: "Caprese",
          Ingredienti: "Bufala, Pomodori",
          Prezzo: "9,00",
          Icons: []
        },
        {
          Nome: "Bresaola",
          Ingredienti: "Bresaola, Rucola, Scaglie di Grana",
          Prezzo: "10,00",
          Icons: []
        },
        {
          Nome: "Hamburger In Piatto",
          Ingredienti: "Hamburger di Manzo, Formaggio, Pancetta, Insalata, Cipolla, Pomodori, Patatine, Salse",
          Prezzo: "12,00",
          Icons: []
        },
        {
          Nome: "Dolci",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Dolci Secchi",
          Ingredienti: "Torte",
          Prezzo: "3,50",
          Icons: []
        },
        {
          Nome: "Dolci al Cucchiaio",
          Ingredienti: "(Di nostra produzione)",
          Prezzo: "4,00",
          Icons: []
        },
        {
          Nome: "Frutta",
          Ingredienti: "Fragole, Ananas",
          Prezzo: "4,00",
          Icons: []
        },
      ]
    }),
  }
</script>
