import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PizzeClassicheView from '../views/PizzeClassicheView.vue'
import PizzeBiancheView from '../views/PizzeBiancheView.vue'
import PizzeriaView from '../views/PizzeriaView.vue'
import RistoranteView from '../views/RistoranteView.vue'
import BarView from '../views/BarView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pizzeria',
    name: 'pizzeria',
    component: PizzeriaView
  },
  {
    path: '/pizzeclassiche',
    name: 'pizzeclassiche',
    component: PizzeClassicheView
  },
  {
    path: '/pizzebianche',
    name: 'pizzebianche',
    component: PizzeBiancheView
  },
  {
    path: '/ristorante',
    name: 'ristorante',
    component: RistoranteView
  },
  {
    path: '/bar',
    name: 'bar',
    component: BarView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
