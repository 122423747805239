<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="8" md="8" lg="6" xl="4">
        <v-card tile class="ma-0">
          <v-img :src="require('../assets/Images/Forno.jpg')" width="100%" max-height="15vh" cover class="ma-0 pa-0">
          <v-card class="ma-0 pa-0" tile height="101%" outlined color="transparent" style="background: linear-gradient(to bottom, transparent 55%, black 100%);">
            <v-card height="60%" outlined tile color="transparent"></v-card>
            <v-card height="20%" outlined tile color="transparent"><h1 style="width: fit-content; margin: auto; color: white;">Pizze Classiche</h1></v-card>
          </v-card>
          </v-img>
        </v-card>
        <PizzaCard v-for="pizza, index in pizzaList" :key="index" :pizza="pizza" class="mb-1" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
  import PizzaCard from '../components/PizzaCard'

  export default {
    name: 'PizzeClassicheView',

    components: {
      PizzaCard,
    },
    data: () => ({
      pizzaList: [
        {
          Nome: "Marinara",
          Ingredienti: "Pomodoro, Aglio, Origano",
          Prezzo: "4,00",
          Immagine: require("../assets/Images/Marinara.jpg"),
          Allergeni: "1",
          Icons: []
        },
        {
          Nome: "Margherita",
          Ingredienti: "Pomodoro, Mozzarella",
          Prezzo: "5,00",
          Immagine: require("../assets/Images/Margherita.jpg"),
          Allergeni: "1, 7",
          Icons: []
        },
        {
          Nome: "Pugliese",
          Ingredienti: "Pomodoro, Mozzarella, Cipolla",
          Prezzo: "5,00",
          Immagine: require("../assets/Images/Pugliese.jpg"),
          Allergeni: "1, 7",
          Icons: []
        },
        {
          Nome: "Wurstel",
          Ingredienti: "Pomodoro, Mozzarella, Wurstel",
          Prezzo: "6,00",
          Immagine: require("../assets/Images/Wurstel.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1"]
        },
        {
          Nome: "Cotto",
          Ingredienti: "Pomodoro, Mozzarella, Cotto",
          Prezzo: "6,00",
          Immagine: require("../assets/Images/Cotto.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1"]
        },
        {
          Nome: "Funghi",
          Ingredienti: "Pomodoro, Mozzarella, Funghi",
          Prezzo: "6,00",
          Immagine: require("../assets/Images/Funghi.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["mushroom:#6D4C41"]
        },
        {
          Nome: "Napoli",
          Ingredienti: "Pomodoro, Mozzarella, Acciughe, Origano",
          Prezzo: "6,50",
          Immagine: require("../assets/Images/Napoli.jpg"),
          Allergeni: "1, 7, 4",
          Icons: ["fish:#1976D2"]
        },
        {
          Nome: "Siciliana",
          Ingredienti: "Pomodoro, Acciughe, Capperi, Olive, Origano",
          Prezzo: "6,50",
          Immagine: require("../assets/Images/Siciliana.jpg"),
          Allergeni: "1, 7, 4",
          Icons: ["fish:#1976D2"]
        },
        {
          Nome: "Romana",
          Ingredienti: "Pomodoro, Mozzarella, Acciughe, Capperi, Origano, Aglio",
          Prezzo: "6,50",
          Immagine: require("../assets/Images/Romana.jpg"),
          Allergeni: "1, 7, 4",
          Icons: ["fish:#1976D2"]
        },
        {
          Nome: "Cotto e Funghi",
          Ingredienti: "Pomodoro, Mozzarella, Cotto, Funghi",
          Prezzo: "7,50",
          Immagine: require("../assets/Images/CottoFunghi.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1", "mushroom:#6D4C41"]
        },
        {
          Nome: "Salsiccia e Cipolla",
          Ingredienti: "Pomodoro, Mozzarella, Salsiccia, Cipolla",
          Prezzo: "7,50",
          Immagine: require("../assets/Images/SalsicciaCipolla.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1"]
        },
        {
          Nome: "Tonno e Cipolla",
          Ingredienti: "Pomodoro, Mozzarella, Tonno, Cipolla",
          Prezzo: "7,50",
          Immagine: require("../assets/Images/TonnoCipolla.jpg"),
          Allergeni: "1, 7, 4",
          Icons: []
        },
        {
          Nome: "Diavola",
          Ingredienti: "Pomodoro, Mozzarella, Salame Piccante",
          Prezzo: "7,50",
          Immagine: require("../assets/Images/Diavola.jpg"),
          Allergeni: "1, 7",
          Icons: ["pig-variant:#F48FB1", "chili-mild:red"]
        },
        {
          Nome: "Monte Carlo",
          Ingredienti: "Pomodoro, Mozzarella, Cotto, Uovo",
          Prezzo: "7,50",
          Immagine: require("../assets/Images/Montecarlo.jpg"),
          Allergeni: "1, 7, 3",
          Icons: ["pig-variant:#F48FB1", "egg:#FDD835"]
        },
        {
          Nome: "Vegetariana",
          Ingredienti: "Pomodoro, Mozzarella, Verdure",
          Prezzo: "8,50",
          Immagine: require("../assets/Images/Vegetariana.jpg"),
          Allergeni: "1, 7",
          Icons: []
        },
        {
          Nome: "Quattro Stagioni",
          Ingredienti: "Pomodoro, Mozzarella, Cotto, Carciofi, Olive, Funghi",
          Prezzo: "8,50",
          Immagine: require("../assets/Images/QuattroStagioni.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1", "mushroom:#6D4C41"]
        },
        {
          Nome: "Capricciosa",
          Ingredienti: "Pomodoro, Mozzarella, Cotto, Carciofi, Olive, Funghi, Salsiccia",
          Prezzo: "8,50",
          Immagine: require("../assets/Images/Capricciosa.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1", "mushroom:#6D4C41"]
        },
        {
          Nome: "Valtellina",
          Ingredienti: "Pomodoro, Mozzarella, Bresaola, Rucola, Scaglie di Grana",
          Prezzo: "9,50",
          Immagine: require("../assets/Images/Valtellina.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["cheese:#FDD835"]
        },
        {
          Nome: "Parma",
          Ingredienti: "Pomodoro, Mozzarella, Crudo, Scaglie di Grana",
          Prezzo: "9,50",
          Immagine: require("../assets/Images/Parma.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1", "cheese:#FDD835"]
        },
        {
          Nome: "Tirolo",
          Ingredienti: "Pomodoro, Mozzarella, Speck, Scamorza Affumicata",
          Prezzo: "9,50",
          Immagine: require("../assets/Images/Tirolo.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1", "cheese:#FDD835"]
        },
        {
          Nome: "Max",
          Ingredienti: "Pomodoro, Mozzarella, Salsiccia, Peperoni, Olive, Capperi, Acciughe",
          Prezzo: "9,50",
          Immagine: require("../assets/Images/Max.jpg"),
          Allergeni: "1, 7, 12, 4",
          Icons: ["pig-variant:#F48FB1", "fish:#1976D2"]
        },
        {
          Nome: "Calzone Normale",
          Ingredienti: "Pomodoro, Mozzarella, Cotto",
          Prezzo: "6,50",
          Immagine: require("../assets/Images/Calzone.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1"]
        },
        {
          Nome: "Calzone Farcito",
          Ingredienti: "Pomodoro, Mozzarella, Cotto, Funghi, Carciofi",
          Prezzo: "8,50",
          Immagine: require("../assets/Images/CalzoneFarcito.jpg"),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1"]
        },        
      ]
    }),
  }
</script>
