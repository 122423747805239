<template>
  <v-app>
    <v-navigation-drawer
      bottom
      v-model="drawer"
      app
      temporary
      color="primary" dark
    >
      <v-list-item class="">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-row align="center">
              <v-col> <b @click="routeTo('/')" class="a-pointer">Menù Ostello Degli Elfi</b> </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link @click="routeTo(item.link)" >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon 
      @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="a-cursor">
        <v-row align="center">
          <v-col> <b @click="routeTo('/')" class="a-pointer">Menù Ostello Degli Elfi</b> </v-col>
        </v-row>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import router from "./router"
export default {
  name: 'App',
  data: () => ({
    items: [
      { title: "Menù Ristorante", icon: "mdi-silverware-fork-knife", link: "/ristorante" },
      { title: "Menù Pizzeria", icon: "mdi-pizza", link: "/pizzeria" },
      { title: "Menù Bar", icon: "mdi-glass-cocktail", link: "/bar" },
    ],
    drawer: false,
  }),
  created() {
    document.title = "Menù Ostello Degli Elfi";
  },
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
  },
};
</script>

<style>
.a-pointer {
  cursor: pointer;
}
i.v-icon.v-icon.i-yellow {
  color: rgb(196, 196, 3);
}
</style>