<template>
  <v-card color="white" class="ma-5 rounded-xl" elevation="8">
    <v-card height="50%" color="white">
      <v-img :src="pizza.Immagine" :lazy-src="pizza.Immagine" width="100%" cover>
      </v-img>
    </v-card>
    <v-card :color="pizza.Prezzo == '' ? 'primary' : 'white'" :dark="pizza.Prezzo == ''" class="pt-2">
      <v-row>
        <v-col :cols="pizza.Prezzo == '' ? '12' : '8'">
          <h1 :class="`text-h6 font-weight-bold ${pizza.Prezzo == '' ? '' : 'ml-2'}  ${pizza.Prezzo == '' ? 'text-center' : ''}`">{{pizza.Nome}}</h1>
        </v-col>
        <v-col cols="4" v-if="pizza.Prezzo != ''">
          <h1 class="text-h6 font-weight-bold ml-2">€ {{pizza.Prezzo}}</h1>
        </v-col>
      </v-row>
      <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis">{{pizza.Ingredienti}}</h1>
      <v-divider v-if="pizza.Icons.length != 0"></v-divider>
      <!-- https://pictogrammers.com/library/mdi/category/agriculture/ -->
      <v-icon v-for="icon, index in pizza.Icons" :key="index" class="mt-3 ml-2" :color="icon.split(':')[1]">mdi-{{icon.split(':')[0]}}</v-icon>
      <v-divider v-if="pizza.Icons.length != 0"></v-divider>
      <h5 v-if="pizza.Icons.length != 0" style="color: #fff;" class="ml-5">spacer</h5>
    </v-card>
  </v-card>
</template>

<script>
  export default {
    name: 'RestCard',
    props: {
      pizza: {
        Type: Object,
        Default: {
          Nome: "",
          Ingredienti: "",
          Prezzo: "00,00",
          Icons: []
        }
      }
    },
  }
</script>
