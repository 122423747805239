<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="8" md="8" lg="6" xl="4">
        <v-card tile class="ma-0">
          <v-img :src="require('../assets/Images/Bar.jpg')" width="100%" max-height="15vh" cover class="ma-0 pa-0">
          <v-card class="ma-0 pa-0" tile height="101%" outlined color="transparent" style="background: linear-gradient(to bottom, transparent 55%, black 100%);">
            <v-card height="60%" outlined tile color="transparent"></v-card>
            <v-card height="20%" outlined tile color="transparent"><h1 style="width: fit-content; margin: auto; color: white;">Menù Bar</h1></v-card>
          </v-card>
          </v-img>
        </v-card>
        <RestCard v-for="pizza, index in pizzaList" :key="index" :pizza="pizza" class="mb-1" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
  import RestCard from '../components/RestCard'

  export default {
    name: 'PizzeClassicheView',

    components: {
      RestCard,
    },
    data: () => ({
      pizzaList: [
        {
          Nome: "Acqua",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Acqua Gasata 0,5L",
          Ingredienti: "",
          Prezzo: "1,00",
          Icons: []
        },
        {
          Nome: "Acqua Naturale 0,5L",
          Ingredienti: "",
          Prezzo: "1,00",
          Icons: []
        },
        {
          Nome: "Acqua Gasata 1L",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Acqua Naturale 1L",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Caffetteria",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Caffè",
          Ingredienti: "",
          Prezzo: "1,10",
          Icons: []
        },
        {
          Nome: "Caffè corretto",
          Ingredienti: "",
          Prezzo: "1,50",
          Icons: []
        },
        {
          Nome: "Caffè decaffeinato",
          Ingredienti: "",
          Prezzo: "1,50",
          Icons: []
        },
        {
          Nome: "Caffè decaffeinato corretto",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Marocchino",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Cappuccino",
          Ingredienti: "",
          Prezzo: "1,50",
          Icons: []
        },
        {
          Nome: "Cappuccino Decaffeinato",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Orzo-Ginseng",
          Ingredienti: "",
          Prezzo: "1,50",
          Icons: []
        },
        {
          Nome: "Latte Macchiato",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Bibite",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Esta The",
          Ingredienti: "",
          Prezzo: "1,00",
          Icons: []
        },
        {
          Nome: "Succhi di Frutta",
          Ingredienti: "",
          Prezzo: "2,50",
          Icons: []
        },
        {
          Nome: "Bibite Gasate",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "Campari Soda",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "The Bottiglietta",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "The Bottiglietta",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "The Bottiglietta",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "Alcolici",
          Ingredienti: "",
          Prezzo: "",
          Icons: []
        },
        {
          Nome: "Amari",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "Spriz",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "Birra Bottiglia 33cc",
          Ingredienti: "",
          Prezzo: "3,00",
          Icons: []
        },
        {
          Nome: "Birra Bottiglia 50cc",
          Ingredienti: "",
          Prezzo: "5,00",
          Icons: []
        },
        {
          Nome: "Vino Bianco Bicchiere",
          Ingredienti: "",
          Prezzo: "1,50",
          Icons: []
        },
        {
          Nome: "Vino Bianco Fermo Bicchiere",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Vino Rosso Bicchiere",
          Ingredienti: "",
          Prezzo: "1,50",
          Icons: []
        },
        {
          Nome: "Vino Rosso Fermo Bicchiere",
          Ingredienti: "",
          Prezzo: "2,00",
          Icons: []
        },
        {
          Nome: "Ortrugo Mosso Bottiglia",
          Ingredienti: "",
          Prezzo: "10,00",
          Icons: []
        },
        {
          Nome: "Ortrugo Fermo Bottiglia",
          Ingredienti: "",
          Prezzo: "12,00",
          Icons: []
        },
        {
          Nome: "Gutturnio Mosso Bottiglia",
          Ingredienti: "",
          Prezzo: "10,00",
          Icons: []
        },
        {
          Nome: "Gutturnio Fermo Bottiglia",
          Ingredienti: "",
          Prezzo: "12,00",
          Icons: []
        },
        {
          Nome: "Prosecco Bicchiere",
          Ingredienti: "",
          Prezzo: "2,50",
          Icons: []
        },
        {
          Nome: "Prosecco Bottiglia",
          Ingredienti: "",
          Prezzo: "20,00",
          Icons: []
        },
      ]
    }),
  }
</script>
