<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-row class="mt-2">
          <v-col
          style="cursor: pointer;"
          v-for="item in items"
          :key="item.id"
          color="grey lighten-3"
          flat
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-card  @click="routeTo(item.url)" dark>
            <v-img :src="item.img" height="200px">
              <v-card height="100%" color="transparent">
                <v-card color="transparent" height="40%" outlined></v-card>
                <v-card color="transparent" height="20%" outlined class="text-center">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col style="background-color: rgba(0,0,0, 0.45); border-radius: 15px;"><h1>{{ item.title }}</h1></v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card>
                <v-card color="transparent" height="40%" outlined></v-card>
              </v-card>
            </v-img>
          </v-card>
        </v-col>
        </v-row>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "../router";

  export default {
    name: 'PizzeriaVeiw',
    data: () => ({
    userId: undefined,
    items: [
      {
        url: '/pizzeclassiche',
        title: "Pizze Classiche",
        img: require('../assets/Images/Forno.jpg')
      },

      {
        url: '/pizzebianche',
        title: "Pizze Bianche",
        img:
          require('../assets/Images/FornoBianco.jpg')
      }
    ],
    loading: true,
    lastAdded: []
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
  },
  }
</script>
