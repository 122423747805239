<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="8" md="8" lg="6" xl="4">
        <v-card tile class="ma-0">
          <v-img :src="require('../assets/Images/FornoBianco.jpg')" width="100%" max-height="15vh" cover class="ma-0 pa-0">
          <v-card class="ma-0 pa-0" tile height="101%" outlined color="transparent" style="background: linear-gradient(to bottom, transparent 55%, black 100%);">
            <v-card height="60%" outlined tile color="transparent"></v-card>
            <v-card height="20%" outlined tile color="transparent"><h1 style="width: fit-content; margin: auto; color: white;">Pizze Bianche</h1></v-card>
          </v-card>
          </v-img>
        </v-card>
        <PizzaCard v-for="pizza, index in pizzaList" :key="index" :pizza="pizza" class="mb-1" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
  import PizzaCard from '../components/PizzaCard'

  export default {
    name: 'PizzeClassicheView',

    components: {
      PizzaCard,
    },
    data: () => ({
      pizzaList: [
        {
          Nome: "Chisolino",
          Ingredienti: "Pasta Liscia, Olio, Sale, Rosmarino",
          Prezzo: "2,00",
          Immagine: require('../assets/Images/Chisolino.jpg'),
          Allergeni: "1",
          Icons: []
        },
        {
          Nome: "Schiacciata",
          Ingredienti: "Mozzarella, Cotto",
          Prezzo: "6,00",
          Immagine: require('../assets/Images/Schiacciata.jpg'),
          Allergeni: "1, 7, 12",
          Icons: ["pig-variant:#F48FB1"]
        },
        {
          Nome: "Bianca Neve",
          Ingredienti: "Mozzarella, Crescenza",
          Prezzo: "6,50",
          Immagine: require('../assets/Images/BiancaNeve.jpg'),
          Allergeni: "1, 7",
          Icons: ["cheese:#FDD835"]
        },
        {
          Nome: "Zola",
          Ingredienti: "Mozzarella, Zola",
          Prezzo: "6,50",
          Immagine: require('../assets/Images/Zola.webp'),
          Allergeni: "1, 7",
          Icons: ["cheese:#FDD835"]
        },
        {
          Nome: "Quattro Formaggi",
          Ingredienti: "Mozzarella, Zola, Crescenza, Grana",
          Prezzo: "8,50",
          Immagine: require('../assets/Images/QuattroFormaggi.webp'),
          Allergeni: "1, 7",
          Icons: ["cheese:#FDD835"]
        },
        {
          Nome: "Genova",
          Ingredienti: "Mozzarella, Crescenza, Pesto",
          Prezzo: "9,00",
          Immagine: require('../assets/Images/Genova.jpg'),
          Allergeni: "1, 7, 8",
          Icons: ["cheese:#FDD835"]
        },
        {
          Nome: "Bufala",
          Ingredienti: "Mozzarella, Bufala, Pomodori",
          Prezzo: "9,50",
          Immagine: require('../assets/Images/Bufala.jpg'),
          Allergeni: "1, 7",
          Icons: ["cheese:#FDD835"]
        },   
        {
          Nome: "Giogi",
          Ingredienti: "Mozzarella, Cotto, Funghi, Zola, Pesto",
          Prezzo: "9,50",
          Immagine: require('../assets/Images/Giogi.jpg'),
          Allergeni: "1, 7, 12, 8",
          Icons: ["cheese:#FDD835", "pig-variant:#F48FB1", "mushroom:#6D4C41"]
        }, 
      ]
    }),
  }
</script>
