<template>
  <v-card color="white" class="ma-5 rounded-xl" elevation="8">
    <v-card height="50%" color="white">
      <v-img :src="pizza.Immagine" :lazy-src="pizza.Immagine" width="100%" cover>
      </v-img>
    </v-card>
    <v-card color="white" class="pt-2">
      <v-row>
        <v-col cols="8">
          <h1 class="text-h5 font-weight-bold ml-2">{{pizza.Nome}}</h1>
        </v-col>
        <v-col cols="4">
          <h1 class="text-h6 font-weight-bold ml-2">€ {{pizza.Prezzo}}</h1>
        </v-col>
      </v-row>
      <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis">{{pizza.Ingredienti}}</h1>
      <v-divider></v-divider>
      <!-- https://pictogrammers.com/library/mdi/category/agriculture/ -->
      <v-icon v-for="icon, index in pizza.Icons" :key="index" class="mt-3 ml-2" :color="icon.split(':')[1]">mdi-{{icon.split(':')[0]}}</v-icon>
      <h5 :class="`ml-2 my-${pizza.Icons.Length > 0 ? '1' : '3'} text-subtitle-2 font-weight-normal`">Modifiche agli ingredienti: € 1,50 - 2,50 caduno</h5>
      <v-divider></v-divider>
      <h1 class="text-subtitle-2 font-weight-normal ml-2"><b class="text-subtitle-1 font-weight-bold">Allergeni: </b>{{pizza.Allergeni}}</h1>
      <h5 style="color: #fff;" class="ml-5">spacer</h5>
    </v-card>
  </v-card>
</template>

<script>
  export default {
    name: 'PizzaCard',
    props: {
      pizza: {
        Type: Object,
        Default: {
          Nome: "",
          Ingredienti: "",
          Prezzo: "00,00",
          Immagine: "",
          Allergeni: "",
          Icons: []
        }
      }
    },
  }
</script>
